var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container PPR" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productOrderCode,
                        callback: function($$v) {
                          _vm.productOrderCode = $$v
                        },
                        expression: "productOrderCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("生产物料编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productMaterialCode,
                        callback: function($$v) {
                          _vm.productMaterialCode = $$v
                        },
                        expression: "productMaterialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      staticClass: "mr10",
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    staticClass: "subnav_wrap",
                    on: { "tab-click": _vm.switchTabHandle },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  _vm._l(_vm.subNavList, function(item) {
                    return _c(
                      "el-tab-pane",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableLoading,
                            expression: "tableLoading"
                          }
                        ],
                        key: item.value,
                        attrs: { label: item.label, name: item.value }
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            refInFor: true,
                            staticClass: "table_list",
                            attrs: {
                              data: _vm.produceList,
                              "row-key": "id",
                              "expand-row-keys": _vm.expands,
                              border: "",
                              fit: "",
                              stripe: ""
                            },
                            on: {
                              "row-click": _vm.rowClick,
                              "expand-change": _vm.rowClick
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "expand" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "actions_wrap mb20 clearfix"
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelToPRDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出成PR Excel")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "fr mr10",
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.childExcelDownload(
                                                      scope.row
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("导出Excel")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-table",
                                          {
                                            ref:
                                              "childMultipleTable_" +
                                              scope.row.id,
                                            refInFor: true,
                                            staticClass: "table_list",
                                            attrs: {
                                              data: scope.row.samplePoriList,
                                              "row-key": "sampleUuid",
                                              "expand-row-keys":
                                                _vm.childExpands,
                                              border: "",
                                              fit: ""
                                            },
                                            on: {
                                              "row-click": _vm.childRowClick,
                                              "expand-change": _vm.childRowClick
                                            }
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: { type: "expand" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(scope) {
                                                      return [
                                                        _c(
                                                          "el-table",
                                                          {
                                                            staticClass:
                                                              "table_list",
                                                            attrs: {
                                                              data:
                                                                scope.row
                                                                  .sampleList,
                                                              border: "",
                                                              fit: ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  fixed: "",
                                                                  label: "行号"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sn
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  fixed: "",
                                                                  label:
                                                                    "原材料物料编号"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sourceMaterialCode
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料类型"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.confirmType
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料确认书编号"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.confirmNumbers
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "原材料消耗数量"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.sourceQuantity
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "供应商"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.supplierShortName
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "出库仓位"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.outVirtualInventoryName
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            ),
                                                            _c(
                                                              "el-table-column",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    "出库库位"
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "default",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var row =
                                                                          ref.row
                                                                        return [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                row.outSubInventoryName
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "行号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productionSn
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "生产物料编号" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productMaterialCode
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "生产数量" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.productPackages
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "期望交期" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.requiredDate
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "入库仓位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inVirtualInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "入库库位" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inSubInventoryName
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "已入库数" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              row.inventoryInNum
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            }),
                                            _c("el-table-column", {
                                              attrs: { label: "备注" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var row = ref.row
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(row.remark) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "生产订单编号" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.productOrderCode) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.createTime) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "申请人" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(row.applicantName) +
                                            "\n              "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "凭证状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right",
                                              width: "300",
                                              trigger: "hover",
                                              "open-delay": "1000"
                                            },
                                            on: {
                                              show: function($event) {
                                                return _vm.checkApprovalProcess(
                                                  row,
                                                  $index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批人：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.fullName))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                {
                                                  staticClass: "block mt10 mb10"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      审批时间：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(
                                                      _vm._s(row.approvalTime)
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "i",
                                                { staticClass: "block" },
                                                [
                                                  _vm._v(
                                                    "\n                      审批意见：\n                      "
                                                  ),
                                                  _c("em", [
                                                    _vm._v(_vm._s(row.content))
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "el-tag",
                                              {
                                                staticClass: "pointer",
                                                attrs: {
                                                  slot: "reference",
                                                  type: _vm._f("statusFilter")(
                                                    row.ppStatus
                                                  )
                                                },
                                                slot: "reference"
                                              },
                                              [_vm._v(_vm._s(row.ppStatusName))]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        row.ppStatus == "Draft" ||
                                        row.ppStatus == "Rejected"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-edit",
                                                  attrs: {
                                                    type: "primary",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "编辑"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-check",
                                                  attrs: {
                                                    type: "success",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "提交审批"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.submitHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row.ppStatus == "Terminated"
                                          ? _c(
                                              "div",
                                              [
                                                _c("el-button", {
                                                  staticClass: "el-icon-delete",
                                                  attrs: {
                                                    type: "danger",
                                                    circle: "",
                                                    size: "mini",
                                                    title: "删除"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deleteHandle(
                                                        $index,
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: _vm.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                }
              ],
              staticClass: "edit_part"
            },
            [
              _c(
                "div",
                { staticClass: "clearfix mb10" },
                [
                  _c(
                    "div",
                    { staticClass: "display_ib mr10" },
                    [
                      _vm._m(0),
                      _c(
                        "el-select",
                        {
                          staticClass: "select_single w200 mr10 mb10",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.productionTypeChange },
                          model: {
                            value: _vm.productProductionType,
                            callback: function($$v) {
                              _vm.productProductionType = $$v
                            },
                            expression: "productProductionType"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "",
                            attrs: { label: "请选择", value: "" }
                          }),
                          _vm._l(_vm.productionTypeList, function(item) {
                            return _c("el-option", {
                              key: item.dictItemValue,
                              attrs: {
                                label: item.dictItemName,
                                value: item.dictItemValue
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm.productProductionType === "001" ||
                  _vm.productProductionType === "003"
                    ? _c(
                        "div",
                        { staticClass: "display_ib mr10" },
                        [
                          _vm._m(1),
                          _c("el-input", {
                            staticClass: "input_single w200 mr10 mb10",
                            attrs: { placeholder: "请输入" },
                            on: { change: _vm.saleOrderIdChange },
                            model: {
                              value: _vm.saleOrderId,
                              callback: function($$v) {
                                _vm.saleOrderId = $$v
                              },
                              expression: "saleOrderId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.productProductionType === "001" ||
                  (_vm.productProductionType === "003" && _vm.customerName)
                    ? _c(
                        "div",
                        { staticClass: "display_ib mr10" },
                        [
                          _c("span", { staticClass: "tag" }, [
                            _vm._v("客户名称：")
                          ]),
                          _c("span", { staticClass: "tag" }, [
                            _vm._v(_vm._s(_vm.customerName))
                          ]),
                          _c("el-input", {
                            staticClass: "input_single w200 mr10 mb10",
                            attrs: { type: "hidden", placeholder: "请输入" },
                            model: {
                              value: _vm.customerId,
                              callback: function($$v) {
                                _vm.customerId = $$v
                              },
                              expression: "customerId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "link inline_block lh36 fr ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportExcelTemplate }
                    },
                    [_vm._v("坯布纱线转面料导入模板")]
                  ),
                  _c("el-button", {
                    staticClass: "fr",
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addEditHandle }
                  })
                ],
                1
              ),
              _c("upload-excel-component", {
                staticClass: "mb20",
                attrs: {
                  "on-success": _vm.importExcelSuccess,
                  "before-upload": _vm.importExcelBeforeUpload
                }
              }),
              _c(
                "div",
                { staticClass: "edit_wrap" },
                [
                  _c("editDoubleTable", {
                    attrs: {
                      tableList: _vm.editList,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.editFieldsList
                    },
                    on: {
                      inputChange: _vm.inputChange,
                      selectChange: _vm.selectChange,
                      selectVisibleChange: _vm.selectVisibleChange,
                      datePickerChange: _vm.datePickerChange,
                      numberInputChange: _vm.numberInputChange,
                      unitNumberInputChange: _vm.unitNumberInputChange,
                      deleteChlidHandle: _vm.deleteEditItemHandle,
                      addHandle: _vm.addEditItemHandle
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt20 fr" },
                    [
                      _c("el-button", { on: { click: _vm.cancelEditHandle } }, [
                        _vm._v("取消")
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSaveDisabled
                          },
                          on: { click: _vm.saveEditHandle }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSubmitDisabled
                          },
                          on: { click: _vm.submitEditHandle }
                        },
                        [_vm._v("提交审批")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.deleteDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.deleteDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.deleteDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmDeleteHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.submitDialogVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.submitDialogVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定提交审批？")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.submitDialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSubmitHandle }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("生产类型：")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "tag" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("销售单号：")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }